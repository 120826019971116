define("discourse/plugins/discourse-files-section/discourse/initializers/file-post-menu", ["exports", "discourse/lib/plugin-api", "virtual-dom"], function (_exports, _pluginApi, _virtualDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "file-post-menu",
    initialize: function (container, application) {
      (0, _pluginApi.withPluginApi)('0.1', api => {
        api.addPostMenuButton('download', post => {
          if (post.upload_url) {
            return {
              icon: 'download',
              className: 'download-file',
              title: 'files.download',
              position: 'first',
              contents: (0, _virtualDom.h)('a', {
                href: post.upload_url,
                download: post.upload_file_name,
                target: '_blank'
              })
            };
          }
        });
      });
    }
  };
});