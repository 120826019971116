define("discourse/plugins/discourse-files-section/discourse/components/files-section-download-button", ["exports", "@glimmer/component", "@ember/object", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FilesSectionDownloadButton extends _component.default {
    static shouldRender(args) {
      console.log("BUTTON", args);
      return true;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div>
          XX
        </div>
      
    */
    {
      "id": "TtbDmThP",
      "block": "[[[1,\"\\n    \"],[10,0],[12],[1,\"\\n      XX\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-files-section/discourse/components/files-section-download-button.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = FilesSectionDownloadButton;
});